@keyframes fadeInChecked {
  from { background: #2E3F5A; }
  to { background: #75CCFA; }
}

@keyframes fadeInClosed {
  from { background: #2E3F5A; }
  to { background: rgb(34, 116, 240); }
}

@keyframes fadeInPath {
  from { background: #2E3F5A; }
  to { background: #D9822B; }
}

table.gridTable {
  border-collapse: separate;
  margin: auto;
  border-spacing: 3px;
}

.gridCell {
  width: calc(min(2.8vw, 2.6vh) - 3px); /* (100vw - 10vw (margin)) / 32 = 2.8125 */
  height: calc(min(2.8vw, 2.6vh) - 3px);
  overflow: hidden;
  background: #2E3F5A;
  border-radius: 5px;
  border: 1px;
}

.gridTooltip,
.gridTooltip :global(.bp3-popover-wrapper) {
  width: calc(min(2.8vw, 2.6vh) - 7px);
  height: calc(min(2.8vw, 2.6vh) - 7px);
  display: block;
}

.gridCell.blocked {
  background-color: #BBBBBB;
}

.gridCell.checked:not(.start):not(.end) {
  animation: fadeInChecked 2s forwards;
}

.gridCell.closed:not(.start):not(.end) {
  animation: fadeInClosed 2s forwards;
}

.gridCell.path:not(.start):not(.end) {
  animation: fadeInPath 2s forwards;
}

.gridCell.start {
  background-color: #4dd276;
}

.gridCell.end {
  background-color: #EC605B;
}

.tooltipRoot {
  padding: 5px;
  font-size: 14px;
}

.tooltipRoot td {
  padding: 5px;
}
